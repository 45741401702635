// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
  import.meta.hot.lastModified = "1712947911932.2556";
}
// REMIX HMR END

import { json, redirect } from "@remix-run/node";
import { useActionData, useLoaderData, useNavigate, useSubmit } from "@remix-run/react";
import { useCallback, useEffect } from "react";
import { getOperator } from "~/utils/auth.server";
import { getHostByDomain } from "~/utils/hosts.server";
import { createThread, retrieveAssistant } from "~/utils/openai.server";
import { upsertThread } from "~/utils/threads.server";
const platformHostNames = ["localhost", "127.0.0.1", "uprate.chat"];
export async function loader({
  request
}) {
  let operator = await getOperator(request);
  if (operator) return redirect("/hosts");
  const url = new URL(request.url);
  if (url.hostname in platformHostNames) return redirect("/explore");
  const host = await getHostByDomain(url.hostname || "");
  if (!host || host.currentVersion === 0) {
    return redirect(`/explore`);
  }
  let assistant = await retrieveAssistant(host?.specs?.assistantId || "");
  if (!assistant) {
    return redirect(`/500`);
  }
  return json({
    host
  });
}
export async function action({
  request
}) {
  const url = new URL(request.url);
  const host = await getHostByDomain(url.hostname || "");
  let threadId = await createThread();
  const thread = {
    openaiThreadId: threadId,
    hostId: host?.oId,
    guestId: ""
  };
  await upsertThread(thread);
  return json({
    threadId
  });
}
export default function Chat() {
  _s();
  const {
    host
  } = useLoaderData();
  const data = useActionData();
  const submit = useSubmit();
  const navigate = useNavigate();
  const getLocalStorage = useCallback(() => {
    let returnValue = null;
    try {
      returnValue = localStorage;
    } catch (error) {
      console.log("Couldn't access localStorage");
    }
    return returnValue;
  }, []);
  const handleThread = useCallback(threadId => {
    const passwordHash = getLocalStorage()?.getItem(`${host?.oId}.${threadId}.passwordHash`);
    if (!host.specs?.password || passwordHash) {
      navigate(`/threads/${threadId}`);
    } else {
      navigate(`/threads/${threadId}/auth/${host.oId}`);
    }
  }, [getLocalStorage, host.oId, host.specs?.password, navigate]);
  useEffect(() => {
    let threadId = getLocalStorage()?.getItem(`${host.oId}.threadId`);
    if (threadId) {
      handleThread(threadId);
    } else {
      submit({}, {
        replace: true,
        method: "POST",
        encType: "application/json"
      });
    }
  }, [getLocalStorage, host.specs?.domain, host.oId, submit, host.specs?.password, handleThread]);
  useEffect(() => {
    if (data?.threadId) {
      getLocalStorage()?.setItem(host.oId + ".threadId", data?.threadId);
      handleThread(data?.threadId);
    }
  }, [getLocalStorage, host.specs?.domain, host.oId, data, handleThread]);
  return;
}
_s(Chat, "6QD8QJPSu5b7Jqd6wewDC1t0xYc=", false, function () {
  return [useLoaderData, useActionData, useSubmit, useNavigate];
});
_c = Chat;
var _c;
$RefreshReg$(_c, "Chat");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;